import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_NON_OPS_HDR_COL_DEF, transferRowData } from "presentation/constant/NonOps/NonOpsHdrColumnDefinition";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable } from "veronica-ui-component/dist/component/core";


const NonOpsHeaderTablePanel:React.FC = () => {
    const [ nonOpsHdrState ] = useNonOpsHeaderTracked();
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    let gridRef: any = useRef();
    
    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_NON_OPS_HDR_COL_DEF.slice());
        
        if (!nonOpsHdrState.selectedManualChargeHeaderRows ||
            nonOpsHdrState.selectedManualChargeHeaderRows.length <= 0) {        
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })


    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        nonOpsHdrVM.updateSelectedManualChargeHeaders(selectedRows);
      }, [nonOpsHdrVM])
    
    const memoNonOpsHdrTable = useMemo(() => {
        return (
        <>            
            <HPHTable
                id='non-ops-header-table'
                isNewColumnSetting={true}
                columns={INITIAL_NON_OPS_HDR_COL_DEF.slice()}
                // headerLabel={'Non Ops / Standard Bill List'}
                data={transferRowData(nonOpsHdrState.manualChargeHeaders)||[]}
                showPaginator={false}
                editable={false}
                showHeaderIcons={true}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                suppressRowClickSelection={false}
                onSelectionChanged={handleSelectionChange}
                onRowDoubleClick={(e: any) => {nonOpsHdrVM.onDetail(e.data)}}
                gridHeight="customHeight"
                customHeight="calc(100vh - 125px)" 
                ref={gridRef}
            />
        </>
        );
    },[handleSelectionChange, nonOpsHdrState.manualChargeHeaders, nonOpsHdrVM])

    return <><TableWrapper>{memoNonOpsHdrTable}</TableWrapper></>;
}

export default memo(NonOpsHeaderTablePanel);
