import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { NonOpsHeaderProvider } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { NonOpsMDView } from "presentation/view/section/NonOps/NonOpsMDView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const NonOpsContMain = () => {
    return <ANAInfoWrapper permission={Permission.NON_OPS_CHARGE}>
        <MessageBarWrapper>
            <NonOpsHeaderProvider>
                <GridStyles/>
                <NonOpsMDView/>
            </NonOpsHeaderProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default NonOpsContMain;