import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { NonOpsSearchCriteria } from "presentation/constant/NonOps/NonOpsSearchCriteria";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import NonOpsHeaderSearchForm from "./NonOpsHeaderSearchForm";

const NonOpsHeaderSearchPanel = () => {
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const [nonOpsHdrState] = useNonOpsHeaderTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        nonOpsHdrVM.onRemoveAllSearchCriteria();
    }, [nonOpsHdrVM]);
    
    const searchButtonClicked = useCallback((criteria:NonOpsSearchCriteria) => {
        setIsLoading(true);
        nonOpsHdrVM.onSearch(criteria,EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [nonOpsHdrVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'Search Criteria'}</SidebarTitle>

                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={nonOpsHdrVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium"/>}

            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <NonOpsHeaderSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(nonOpsHdrState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default NonOpsHeaderSearchPanel;
