import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import NonOpsDetailContMain from "presentation/view/container/NonOps/NonOpsDetailContMain";
import React from "react";
import NonOpsHeaderMaintenance from "./NonOpsHeaderMaintenance";

export const NonOpsMDView: React.FC = () => {
     const [nonOpsHdrState] = useNonOpsHeaderTracked();
     const isShowDetail = nonOpsHdrState.isShowDetail;
   return <>
        {!isShowDetail 
            && <div className="main-comp-wrapper" >
            <NonOpsHeaderMaintenance/>
        </div>}
    {
        isShowDetail && <div className="main-comp-wrapper" ><NonOpsDetailContMain/> </div>
    } 

    </>
}
