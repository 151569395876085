
import { nonOpsHdrStateCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { billingCycleDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { ManualChargeConstant } from "presentation/constant/ManualCharge/ManualChargeConstant";
import { NonOpsConstant } from "presentation/constant/NonOps/NonOpsConstant";
import { useNonOpsHeaderVM } from "presentation/hook/NonOps/useNonOpsHeaderVM";
import { useNonOpsHeaderTracked } from "presentation/store/NonOps/NonOpsHeaderProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { GroupCheckboxList, HPHGroupCheckbox, InputDropdown, InputField } from "veronica-ui-component/dist/component/core";

const NonOpsHeaderSearchForm = () => {   
    const [ nonOpsHdrState ] = useNonOpsHeaderTracked();
    const nonOpsHdrVM = useNonOpsHeaderVM();
    const NON_OPS_HDR_CONST = NonOpsConstant.Header; 
    const MAN_CHG_HDR_CONST = ManualChargeConstant.Header; 
    const headerSearchCriteria = nonOpsHdrState.searchCriteria;
    

    const memoBillingCycle = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={NON_OPS_HDR_CONST.BILLING_CYCLE}
                width='330px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                 value={headerSearchCriteria.billingCycleList?.map((item) => ({
                     value: item
                 }))}
                onChange={(e) => {
                    nonOpsHdrVM.onMultipleDropdownChange(e, 'billingCycleList')
                }}
                options={billingCycleDropdownOption}/>
        </div>
    , [NON_OPS_HDR_CONST.BILLING_CYCLE, headerSearchCriteria.billingCycleList, nonOpsHdrVM])

    // const memoState = useMemo(() =>
    //     <div className='im-flex-row-item'>
    //         <InputDropdown
    //             label={NON_OPS_HDR_CONST.STATE}
    //             inputType="freeText"
    //             width="150px"
    //             mode={'multiple'}
    //             sort={false}
    //             value={headerSearchCriteria.stateList?.map((item) => ({
    //                 value: item
    //             }))}
    //             onChange={(e) => nonOpsHdrVM.onMultipleDropdownChange(e, 'stateList')}
    //             options={manHdrStateDropdownOption}/>
    //     </div>
    // , [NON_OPS_HDR_CONST.STATE, headerSearchCriteria.stateList, nonOpsHdrVM])

    const memoState = useMemo(() =>            
        <div className='im-flex-row-item'>
            <HPHGroupCheckbox
                label={MAN_CHG_HDR_CONST.STATE}
                checkboxData={nonOpsHdrStateCheckboxOption}
                selectedValues={headerSearchCriteria.stateList}
                orientation={"horizontal"}
                onChange={(e: (GroupCheckboxList | undefined)[]) => nonOpsHdrVM.onGroupCheckboxChange(e, headerSearchCriteria, "stateList")}
            />
        </div>
    , [MAN_CHG_HDR_CONST.STATE, headerSearchCriteria, nonOpsHdrVM])

    const memoCustCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={NON_OPS_HDR_CONST.CUST_CODE}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                value={headerSearchCriteria.customerCodeList?.map((item) => ({
                     value: item
                 }))}
                onChange={(e) => nonOpsHdrVM.onMultipleDropdownChange(e, 'customerCodeList')}
                options={nonOpsHdrState.dynamicOptions.customerCodeDropdownOptions}/>
        </div>
    , [NON_OPS_HDR_CONST.CUST_CODE, headerSearchCriteria.customerCodeList, nonOpsHdrState.dynamicOptions.customerCodeDropdownOptions, nonOpsHdrVM])

    const memoRefNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={10}
                label={NON_OPS_HDR_CONST.REF_NO}
                type="text"
                value={headerSearchCriteria.refNo || ''}
                onChange={(e) => nonOpsHdrVM.onInputTextChange(e, 'refNo')}/>
        </div>
    , [NON_OPS_HDR_CONST.REF_NO, headerSearchCriteria.refNo, nonOpsHdrVM])

    const memoInvCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={12}
                label={NON_OPS_HDR_CONST.INV_CR_NO}
                type="text"
                value={headerSearchCriteria.invCreditNoteNo || ''}
                onChange={(e) => nonOpsHdrVM.onInputTextChange(e, 'invCreditNoteNo')}/>
        </div>
    , [NON_OPS_HDR_CONST.INV_CR_NO, headerSearchCriteria.invCreditNoteNo, nonOpsHdrVM])

    const menoStdBill = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={10}
                label={NON_OPS_HDR_CONST.STD_BILL}
                type="text"
                value={headerSearchCriteria.stdBill || ''}
                onChange={(e) => nonOpsHdrVM.onInputTextChange(e, 'stdBill')}/>
        </div>
    , [NON_OPS_HDR_CONST.STD_BILL, headerSearchCriteria.stdBill, nonOpsHdrVM])

    const menoOrderNo = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={10}
                label={NON_OPS_HDR_CONST.ORDER_NO}
                type="text"
                value={headerSearchCriteria.orderNo || ''}
                onChange={(e) => nonOpsHdrVM.onInputTextChange(e, 'orderNo')}/>
        </div>
    , [NON_OPS_HDR_CONST.ORDER_NO, headerSearchCriteria.orderNo, nonOpsHdrVM])

    const memoCreatedDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={MAN_CHG_HDR_CONST.CREATED_DATE_RANGE}
                width='330px'
                fieldNames={{startField:"createdDateFrom", endField:"createdDateTo"}}
                dateRange={{startDate:headerSearchCriteria.createdDateFrom, endDate:headerSearchCriteria.createdDateTo}}
                onDatesChange={nonOpsHdrVM.onDateRangeChange}
            />                
        </div>
    , [MAN_CHG_HDR_CONST.CREATED_DATE_RANGE, headerSearchCriteria.createdDateFrom, headerSearchCriteria.createdDateTo, nonOpsHdrVM.onDateRangeChange])


    return <>        
        
        <CriteriaItemContainer>
           {memoState}
        </CriteriaItemContainer>        

        <CriteriaItemContainer>
           {memoRefNo}                   
           {menoStdBill}
        </CriteriaItemContainer>                   

        <CriteriaItemContainer> 
            {memoBillingCycle}   
        </CriteriaItemContainer>      

        <CriteriaItemContainer>
            {memoCustCode}
         </CriteriaItemContainer>
        
        <CriteriaItemContainer>
        {memoInvCreditNoteNo} 
           {menoOrderNo}
        </CriteriaItemContainer>

        <CriteriaItemContainer>
           { memoCreatedDateFromTo }
        </CriteriaItemContainer>
    </>;
}
export default memo(NonOpsHeaderSearchForm);