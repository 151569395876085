// import { EMPTY_INVENTORY_SEARCH_CRITERIA, InventorySearchCriteria } from "presentation/constant/InventorySearch/InventorySearchCriteria";

import { SearchSortEntity } from "presentation/model/SearchSortEntity"
import { ValueColumnEntity } from "presentation/model/ValueColumnEntity"

export interface SearchModeEntity {
    startRow: number
    endRow: number
    pivotMode: boolean //toggle
    rowGroupCols: string[] //group by
    groupKeys: string[] //open group key
    valueCols: ValueColumnEntity[]
    searchSort?: SearchSortEntity,
    currentGroup?: string,
    // searchCriteria: InventorySearchCriteria,
}

export const EMPTY_SEARCH_MODE_ENTITY: SearchModeEntity = {
    startRow: 0,
    endRow: 0,
    pivotMode: false,
    rowGroupCols: [],
    groupKeys: [],
    valueCols: [],
    // searchCriteria: { ...EMPTY_INVENTORY_SEARCH_CRITERIA },
}